
import {
  useEffect,
  useRef,
} from 'react'

import co from '../core/co'

import useStore from '../core/store'
import M from '../model'

import * as DB from '../db'


export function MapListLoader(props) {
  const {dispatch} = useStore()
  const {current: S} = useRef({})
  S.dispatch = dispatch

  useEffect(() => co(function* () {
      const mapids = yield DB.getMapList()
      for (const mapid of mapids) {
        const mapdef = {}
        for (const metaid of ['spec', 'usage'])
          mapdef[metaid] = yield DB.getMapMeta(mapid, metaid)
        yield S.dispatch.resolved(M.maps.mapLoaded(mapid, mapdef))
      }
      S.dispatch(M.maps.setLoading(false))
    }).cancel, [S])

  return null
}

