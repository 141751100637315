
import React from 'react'
import {
  useMemo,
} from 'react'

import useStore from './core/store'
import M from './model'
import MapViewer from './MapViewer'
import { coordsToTileid } from './maputils'
import * as Leaflet from './Leaflet'
import * as DB from './db'


export function capturedTileLayer(mapid, spec) {
  const tileLayer = Leaflet.L.tileLayer('captured', {
      ...spec.source.options,
      maxZoom: spec.maxZoom,
      minZoom: spec.minZoom,
      bounds: spec.bounds,
    })

  tileLayer.createTile = function (coords, done) {
      var tile = document.createElement('img')
      tile.alt = ''
      tile.setAttribute('role', 'presentation')
      const tileid = coordsToTileid(coords)
      DB.getMapTile(mapid, tileid).then(tileImg => {
          if (!tileImg) {
            console.log(`${mapid}: tile ${tileid} not found`)
            return
          }
          const blob = new Blob([tileImg.buf], {type: tileImg.type})
          const url = URL.createObjectURL(blob)
          tile.addEventListener('load', () => {
              URL.revokeObjectURL(url)
              done(null, tile)
            })
          tile.src = url
        })
      // XXX on error?
      return tile
    }

  return tileLayer
}


// props:
//   mapid
function OfflineMap(props) {
  const {mapid} = props
  const {state} = useStore()

  const map = M.maps.getMap(state, mapid)
  const spec = map.spec

  const initialView = useMemo(() => ({
      center: spec.center,
      zoom: spec.minZoom,
    }), [spec])

  const tileLayer = useMemo(() => capturedTileLayer(mapid, spec), [mapid, spec])

  const options = useMemo(() => ({
      maxBounds: spec.bounds,
    }), [spec])

  return (
    <MapViewer
      key={mapid}
      tileLayer={tileLayer}
      options={options}
      view={initialView}
      buttons={['flytolocation', 'follow']}
    />
  )
}
export default OfflineMap

