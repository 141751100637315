
import React from 'react'


export default function SettingsView(props) {
  const {
      updateAvailable,
      onCheckForUpdate,
      onAcceptUpdate,
      totalTiles,
    } = props

  return (
    <div className="container">

      <div className="row my-4">
        <div className="col my-auto">
          {updateAvailable ? (
            <span>App update available</span>
          ) : (
            <span>App is up-to-date</span>
          )}
        </div>
        <div className="col-auto">
          {updateAvailable ? (
          <button
            className={"btn btn-sm btn-primary"}
            onClick={onAcceptUpdate}
          >
            Apply
          </button>
          ) : (
          <button
            className="btn btn-sm btn-secondary"
            onClick={onCheckForUpdate}
          >
            Check
          </button>
          )}
        </div>
      </div>

      <div className="row my-4">
        <div className="col my-auto">
          Storage Use
        </div>
        <div className="col-auto my-auto text-right sandstone">
          {totalTiles} tiles
        </div>
      </div>

    </div>
  )
}

