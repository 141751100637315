
// slippy math
// from https://wiki.openstreetmap.org/wiki/Slippy_map_tilenames#ECMAScript_.28JavaScript.2FActionScript.2C_etc..29
export const slippyMath = {

  lon2tile: (lon, zoom) => Math.floor((lon+180)/360*Math.pow(2,zoom)),

  lat2tile: (lat, zoom) => Math.floor((1-Math.log(Math.tan(lat*Math.PI/180)+1/Math.cos(lat*Math.PI/180))/Math.PI)/2*Math.pow(2,zoom)),

  tile2lon: (x, z) => (x/Math.pow(2,z)*360-180),

  tile2lat: (y, z) => {
      var n=Math.PI-2*Math.PI*y/Math.pow(2,z)
      return (180/Math.PI*Math.atan(0.5*(Math.exp(n)-Math.exp(-n))))
    },

  // b = [nw, se] = [[lat, lon], [lat, lon]]
  nTilesInBounds: (b, z) => {
      const n = slippyMath.lat2tile(b[0][0], z)
      const w = slippyMath.lon2tile(b[0][1], z)
      const s = slippyMath.lat2tile(b[1][0], z)
      const e = slippyMath.lon2tile(b[1][1], z)
      return (Math.abs(w - e) + 1) * (Math.abs(n - s) + 1)
    },
}


export const coordsToTileid = coords => (`${coords.z}/${coords.x}/${coords.y}`)

