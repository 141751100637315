
import {
  useEffect,
  useRef,
} from 'react'

import co from '../core/co'

import useStore from '../core/store'
import M from '../model'

import {slippyMath} from '../maputils'

import * as DB from '../db'


function totalTiles(bounds, minZoom, maxZoom) {
  var nTiles = 0
  for (let z = minZoom; z <= maxZoom; z++)
    nTiles += slippyMath.nTilesInBounds(bounds, z)
  return nTiles
}


export function MapCaptureController(props) {
  const {state, dispatch, select, take} = useStore()
  const {current: S} = useRef({})
  S.dispatch = dispatch
  S.select = select
  S.take = take
  S.canStart = M.mapCapture.getCanStart(state)
  S.canCommit = M.mapCapture.getCanCommit(state)

  const mapView = M.onlineMap.getView(state)
  const maxZoom = M.onlineMap.getSource(state).options.maxNativeZoom
  const maxNTiles = M.mapCapture.getMaxNTiles(state)
  const definition = M.mapCapture.getDefinition(state)

  // monitor online map view to see if we can capture the tiles
  //XXX better to do this during reduction
  useEffect(() => {
      var canStart = false
      if (mapView.bounds) {
        const nTiles = totalTiles(mapView.bounds, mapView.zoom, maxZoom)
        canStart = nTiles <= maxNTiles
      }
      if (canStart !== S.canStart)
        S.dispatch(M.mapCapture.setCanStart(canStart))
    }, [mapView, maxZoom, maxNTiles, dispatch, S])

  // monitor proposed map definition to see if we can commit
  //XXX better to do this during reduction
  useEffect(() => {
      const canCommit =
        !!definition &&
        !!definition.usage &&
        !!definition.usage.name &&
        !!definition.spec
      if (canCommit !== S.canCommit)
        S.dispatch(M.mapCapture.setCanCommit(canCommit))
    }, [definition, S])

  // manage the capture process
  useEffect(() => co(function* () {
      while (true) {
        yield S.take(M.mapCapture.actions.START)
        const canStart = S.select(M.mapCapture.getCanStart)
        if (!canStart) continue;
        // construct the definition
        const mapSource = S.select(M.onlineMap.getSource)
        const mapView = S.select(M.onlineMap.getView)
        const maxZoom = mapSource.options.maxNativeZoom
        const definition = {
            spec: {
              source: mapSource,
              center: mapView.center,
              bounds: mapView.bounds,
              minZoom: mapView.zoom,
              maxZoom,
              nTiles: totalTiles(mapView.bounds, mapView.zoom, maxZoom),
            },
            usage: {
              name: ''
            },
          }
        // store it
        yield S.dispatch.resolved(M.mapCapture.setDefinition(definition))
        // open the dialog
        S.dispatch(M.mapCapture.setDialogIsOpen(true))
        // wait for commit or cancel
        var commit = false
        while (true) {
          const {type: a} = yield S.take([
              M.mapCapture.actions.COMMIT, M.mapCapture.actions.CANCEL,
            ])
          if (a === M.mapCapture.actions.COMMIT &&
              !S.select(M.mapCapture.getCanCommit))
            continue
          commit = a === M.mapCapture.actions.COMMIT
          break
        }
        if (commit) {
          const mapdef = S.select(M.mapCapture.getDefinition)
          mapdef.usage.incomplete = true
          const mapid = yield DB.createMap(mapdef)
          S.dispatch(M.maps.mapLoaded(mapid, mapdef))
          window.location.hash = '#/maps'
        }
        S.dispatch(M.mapCapture.setDialogIsOpen(false))
      }
    }).cancel, [S])

  return null
}

