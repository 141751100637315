
import {
  useEffect,
  useRef,
  useState,
} from 'react'

import co from '../core/co'

import useStore from '../core/store'
import M from '../model'

import {slippyMath} from '../maputils'

import * as DB from '../db'

// leaflet
import L from 'leaflet/dist/leaflet.js'

import {onlineTileLayer} from '../OnlineMap'
import {coordsToTileid} from '../maputils'



export function* generateTileUrls(mapdef) {
  const divElement = document.createElement('div')
  const map = L.map(divElement, {
      center: mapdef.spec.center,
      zoom: mapdef.spec.minZoom,
    })
  const tileLayer = onlineTileLayer(mapdef.spec.source)
  tileLayer.addTo(map)
  map.addLayer(tileLayer)

  for (let z = mapdef.spec.minZoom; z <= mapdef.spec.maxZoom; z++) {
    const x1 = slippyMath.lon2tile(mapdef.spec.bounds[0][1], z)
    const x2 = slippyMath.lon2tile(mapdef.spec.bounds[1][1], z)
    const y1 = slippyMath.lat2tile(mapdef.spec.bounds[0][0], z)
    const y2 = slippyMath.lat2tile(mapdef.spec.bounds[1][0], z)
    const xmin = Math.min(x1, x2)
    const xmax = Math.max(x1, x2)
    const ymin = Math.min(y1, y2)
    const ymax = Math.max(y1, y2)
    for (let x = xmin; x <= xmax; x++) {
      for (let y = ymin; y <= ymax; y++) {
        const coords = L.point(x, y)
        coords.z = z
        const url = tileLayer.getTileUrl(coords)
        const id = coordsToTileid(coords)
        yield ([id, url])
      }
    }
  }

  // XXX won't happen if generator doesn't run to completion?
  map.remove()
  return
}


export function captureTile(mapid, tileid, tileurl) {
  return fetch(tileurl).then(response =>
      response.arrayBuffer().then(buf => ({
        type: response.headers.get('Content-Type'),
        buf}))
    )
    .then(imagedata => DB.putMapTile(mapid, tileid, imagedata))
}


export function MapTileDownloader(props) {
  const {dispatch, select, take} = useStore()
  const {current: S} = useRef({})
  S.dispatch = dispatch
  S.select = select
  S.take = take

  const [queue, setQueue] = useState([])
  const mapid = queue[0]

  useEffect(() => co(function* () {
      while (true) {
        const {mapid} = yield S.take(M.maps.actions.MAPLOADED)
        setQueue(q => [...q, mapid])
      }
    }).cancel, [setQueue, S])

  useEffect(() => co(function* () {
      if (!mapid) return;
      const mapdef = S.select(M.maps.getMap, mapid)
      if (!mapdef.usage.incomplete) {
        setQueue(([top, ...q]) => q)
        return;
      }
      // get list of already downloaded tiles
      const already = yield DB.getMapTileList(mapid)
      // track progress
      var progress = already.length
      function updateProgress(increment) {
          progress += increment
          S.dispatch(M.maps.setProgress(mapid,
            Math.ceil((progress*100)/mapdef.spec.nTiles)))
        }
      updateProgress(0)
      // download tiles
      const pending = {}
      const tileList = generateTileUrls(mapdef)
      for (let [tileid, tileurl] of tileList) {
        if (already.includes(tileid)) continue;
        pending[tileid] = captureTile(mapid, tileid, tileurl).then(() => tileid)
        if (Object.values(pending).length > 9) {
          delete pending[yield Promise.race(Object.values(pending))]
          updateProgress(1)
        }
      }
      while (Object.values(pending).length > 0) {
          delete pending[yield Promise.race(Object.values(pending))]
          updateProgress(1)
        }
      // mark complete
      yield S.dispatch.resolved(M.maps.markComplete(mapid))
      DB.putMapMeta(mapid, 'usage', S.select(M.maps.getMap, mapid).usage)

      setQueue(([top, ...q]) => q)
    }).cancel, [mapid, S])

  return null
}


