
import React from 'react'
import {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

// fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBars,
  faCrosshairs,
  faFileDownload,
  faLocationArrow,
  faSearch,
} from '@fortawesome/free-solid-svg-icons'

import * as Leaflet from './Leaflet'
import useStore from './core/store'
import M from './model'
import { MenuButtonDropdownView } from './MenuButtonView'
import GeoLocationMarker from './GeoLocationMarker'



const buttonStyle = { fontSize: '1.65rem' }

export function MapMenuButtonView(props) {
  return (
    <div
      style={{
        position: 'absolute',
        left: 0,
        top: 0,
        pointerEvents: 'none',
        zIndex: 2,
      }}
    >
      <div className="dropdown" style={{pointerEvents: 'auto'}}>
        <button
          className="btn btn-block my-1 text-primary"
          style={{...buttonStyle}}
          data-toggle="dropdown"
        >
          <FontAwesomeIcon icon={faBars} fixedWidth />
        </button>
        <MenuButtonDropdownView />
      </div>
    </div>
  )
}


export function MapButtonBarView(props) {
  return (
    <div
      style={{
        position: 'absolute',
        right: 0,
        top: 0,
        pointerEvents: 'none',
        zIndex: 2,
      }}
    >
      {props.children}
    </div>
  )
}


export function MapButtonView(props) {
  const enabledClass = !props.onClick ? "text-muted" :
                         props.active ? "text-success" :
                                        "text-primary"
  return (
    <div
      className={`btn btn-block my-1 ${enabledClass}`}
      onClick={props.onClick}
      style={{
        pointerEvents: 'auto',
        ...buttonStyle,
      }}
    >
      {props.children}
    </div>
  )
}


const CaptureButton = (props) => {
  return (
    <MapButtonView onClick={props.startCapture}>
      <FontAwesomeIcon icon={faFileDownload} fixedWidth />
    </MapButtonView>
  )
}


const FlyToLocationButton = (props) => {
  return (
    <MapButtonView onClick={props.flyTo}>
      <FontAwesomeIcon icon={faCrosshairs} fixedWidth />
    </MapButtonView>
  )
}


const FollowButton = (props) => {
  return (
    <MapButtonView onClick={props.toggleFollow} active={props.following}>
      <FontAwesomeIcon icon={faLocationArrow} fixedWidth transform="shrink-2" />
    </MapButtonView>
  )
}


const SearchButton = (props) => {
  return (
    <MapButtonView disabled>
      <FontAwesomeIcon icon={faSearch} fixedWidth />
    </MapButtonView>
  )
}


function MapButtonsView(props) {
  const {
      which,
      ...buttonProps
    } = props

  const buttons = {
      capture: CaptureButton,
      flytolocation: FlyToLocationButton,
      follow: FollowButton,
      search: SearchButton,
    }

  return (
    <MapButtonBarView>
      {which.map(w => {
        let B = buttons[w]
        return <B key={w} {...buttonProps} />
      })}
    </MapButtonBarView>
  )
}


export default function MapViewer(props) {
  const {
      buttons,
      onViewChange,
      options,
      tileLayer,
      view,
    } = props

  const {state, dispatch} = useStore()
  const geolocation = M.geolocation.get(state)
  const canCapture = M.mapCapture.getCanStart(state)
  const haveLocation = !!geolocation.location

  const [reflectedView, setReflectedView] = useState(view)
  useEffect(() => setReflectedView(view), [view])

  const [following, setFollowing] = useState(false)

  const {current: S} = useRef({})
  S.dispatch = dispatch
  S.geolocation = geolocation
  S.startCapture = () => S.dispatch(M.mapCapture.start())
  S.flyTo = () => {
      const center = Leaflet.L.latLng(S.geolocation.location)
      const bounds = center.toBounds(S.geolocation.accuracy * 2)
      setReflectedView({bounds})
    }
  S.toggleFollow = () => setFollowing(f => !f)

  const canFlyTo = haveLocation && (!options.maxBounds ||
                   Leaflet.L.latLngBounds(options.maxBounds).contains(geolocation.location))
  const buttonProps = useMemo(() => ({
      startCapture: canCapture ? S.startCapture : undefined,
      flyTo: canFlyTo ? S.flyTo : undefined,
      toggleFollow: canFlyTo ? S.toggleFollow : undefined,
      following,
    }), [canCapture, canFlyTo, following, S])

  useEffect(() => {
      if (!following) return;
      if (!geolocation.location) return;
      S.flyTo()
    }, [following, geolocation, S])

  return (
    <div style={{width: "100%", height: "100%"}} >
      <Leaflet.Map
        view={reflectedView}
        onViewChange={onViewChange}
        options={{zoomControl: false, ...options}}
        style={{width: "100%", height: "100%", zIndex: 1}}
      >
        <Leaflet.LayerOnMap layer={tileLayer} />
        <GeoLocationMarker
          location={geolocation.location}
          heading={geolocation.heading}
          stale={geolocation.stale}
        />
      </Leaflet.Map>
      <MapMenuButtonView />
      <MapButtonsView which={buttons} {...buttonProps} />
    </div>
  )
}

