import React from 'react'
import {
  useEffect,
  useRef,
  useState,
} from 'react'

// fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCrosshairs,
//  faInfoCircle,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'

import Hammer from 'hammerjs'


export function MapListItemView(props) {
  const {
      name,
      onMenuOpen,
      menuIsOpen,
      onDelete,
    } = props
  const progress = props.incomplete ? (
                       props.progress !== undefined ?
                           Math.max(props.progress, 5)
                         : 0
                     ) : undefined

  const {current: S} = useRef({})
  S.onMenuOpen = onMenuOpen
  const slideHandleRef = useRef()
  useEffect(() => {
      if (!slideHandleRef.current) return;
      const h = new Hammer.Manager(slideHandleRef.current, {
          recognizers: [
              [Hammer.Swipe, {
                  direction: Hammer.DIRECTION_HORIZONTAL,
                  threshold: 7,
                  velocity: 0.1,
                }],
            ],
        })
      h.on('swipeleft', e => S.onMenuOpen(true))
      h.on('swiperight', e => S.onMenuOpen(false))
      return () => h.destroy()
    }, [S])

  return (
    <div className="list-group-item m-0 p-0">
    <div className="row m-0 p-0">
      {/* column of item content */}
      <div className="col m-0 p-0">
        <div className="p-3" style={{minHeight: '3.5rem'}}>
        <div className="row">
          {/* name and progress bar */}
          <div className="col" ref={slideHandleRef}>
            <a
              className={`list-group-item-action ${props.incomplete ? "disabled" : ""}`}
              href={props.incomplete ? undefined : props.openMapHref}
            >
              <div className="text-truncate">{name}</div>
              {progress !== undefined ? (
              <div className="progress" style={{height: "4px"}}>
                <div
                  className="progress-bar progress-bar-striped progress-bar-animated"
                  role="progressbar"
                  style={{width: `${progress}%`}}
                ></div>
              </div>) : null}
            </a>
          </div>
          {/* button to open slide menu */}
          <div className="col-1 pl-0">
            {props.contains ? (
            <span>
              <FontAwesomeIcon icon={faCrosshairs} fixedWidth />
            </span>
            ) : null}
{/*
            <span
              onClick={() => setSideMenuOpen(o => !o)}
            >
              <FontAwesomeIcon icon={faEllipsisV} fixedWidth />
            </span>
*/}
          </div>
        </div>
        </div>
      </div>
      {/* slide menu */}
      <div
        className="col-auto m-0 p-0"
        style={{
            minHeight: "3.5rem",
            maxWidth: menuIsOpen ? "50%" : "0px",
            transition: "all .3s ease-in-out",
            overflow: "hidden",
          }}
      >
        <div className="row m-0 p-0 h-100">
{/*
        <div
          className="col-auto btn-info text-center h-100 d-flex justify-content-center align-items-center"
          style={{width: "3.5rem", borderRadius: "0px"}}
        >
          <FontAwesomeIcon icon={faInfoCircle} fixedWidth size="lg" />
        </div>
*/}
        <div
          className="col-auto btn-danger text-center h-100 d-flex justify-content-center align-items-center"
          style={{width: "3.5rem", borderRadius: "0px"}}
          onClick={onDelete}
        >
          <FontAwesomeIcon icon={faTrash} fixedWidth size="lg" />
        </div>
        </div>
      </div>
    </div>
    </div>
  )
}

export default function MapListView(props) {
  const maps = [...props.maps]
  maps.sort((a, b) => {
      const aName = a.name.toLowerCase()
      const bName = b.name.toLowerCase()
      if (aName < bName) return -1;
      if (aName > bName) return 1;
      return 0
    })
  const nearby = maps.filter(m => m.nearby)
  const others = maps.filter(m => !m.nearby)

  const [menuOpen, setMenuOpen] = useState()

  return (
    <div className="list-group list-group-flush">
      {nearby.length > 0 && others.length > 0 ? (
      <div className="mb-0 mt-3 p-0 text-center text-muted sandstone">
        Nearby
      </div>
      ) : null}
      {nearby.map(m => (<MapListItemView
                          key={`/nearby/${m.mapid}`}
                          name={m.name}
                          incomplete={m.incomplete}
                          progress={m.progress}
                          openMapHref={m.openMapHref}
                          contains={m.contains}
                          menuIsOpen={menuOpen === `/nearby/${m.mapid}`}
                          onMenuOpen={open => setMenuOpen(
                            open ? `/nearby/${m.mapid}` : undefined)}
                          onDelete={() => {
                              setMenuOpen(undefined)
                              m.onDelete()
                            }}
                        />))}
      {nearby.length > 0 && others.length > 0 ? (
      <div className="mb-0 mt-3 p-0 text-center text-muted sandstone">
        Others
      </div>
      ) : null}
      {others.map(m => (<MapListItemView
                         key={`/all/${m.mapid}`}
                         name={m.name}
                         incomplete={m.incomplete}
                         progress={m.progress}
                         openMapHref={m.openMapHref}
                         contains={m.contains}
                         menuIsOpen={menuOpen === `/all/${m.mapid}`}
                         onMenuOpen={open => setMenuOpen(
                           open ? `/all/${m.mapid}` : undefined)}
                         onDelete={() => {
                             setMenuOpen(undefined)
                             m.onDelete()
                           }}
                       />))}
    </div>
  )
}

