
import {
  useEffect,
  useRef,
} from 'react'

import L from 'leaflet/dist/leaflet.js'

import M from '../model'
import useStore from '../core/store'


export function GeolocationController(props) {
  const {state, dispatch} = useStore()
  const {current: S} = useRef({})
  S.dispatch = dispatch

  const currentLocation = M.geolocation.get(state).location

  const mapCenter = undefined //XXX
  const enableHighAccuracy = true || (  //XXX
      !!currentLocation &&
      !!mapCenter &&
      L.latLng(currentLocation).distanceTo(mapCenter) <= 1000 // 1km
    )

  useEffect(() => {
      if (!navigator.geolocation) return;
      const watchid = navigator.geolocation.watchPosition(p => {
          S.dispatch(M.geolocation.update({
              location: [p.coords.latitude, p.coords.longitude],
              heading: p.coords.heading,
              altitude: p.coords.altitude,
              accuracy: p.coords.accuracy,
              altitudeAccuracy: p.coords.altitudeAccuracy,
              timestamp: p.timestamp,  //XXX just set stale?
            }))
        }, null, {
          enableHighAccuracy,
        })
      return () => navigator.geolocation.clearWatch(watchid)
    }, [enableHighAccuracy, S])

  return null
}

