
import {
  useEffect,
  useRef,
} from 'react'

import useStore from '../core/store'
import M from '../model'


export function BrowserLocationController(props) {
  const {dispatch} = useStore()
  const {current: S} = useRef({})
  S.dispatch = dispatch

  useEffect(() => {
      const handleHashChange = () => {
          var h = window.location.hash.slice(1)
          if (h[0] !== '/') {
            h = '/' + h
            window.history.replaceState(null, null, `#${h}`)
            // replaceState does not trigger a hashchange event,
            // so fall through:
          }
          if (!S.location ||
              S.location.toString().slice(5) !== h) {
            S.location = new URL(`hash:${h}`)
            S.dispatch(M.browser.reflectLocation(S.location))
          }
        }
      handleHashChange()
      window.addEventListener('hashchange', handleHashChange)
      return () => window.removeEventListener('hashchange', handleHashChange)
    }, [S])

  return null
}

