import React from 'react'
import {
  useEffect,
  useMemo,
  useState,
} from 'react'

// fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCog,
  faFolder,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons'

import * as Leaflet from './Leaflet'
import useStore, {WithReducerMap} from './core/store'
import M from './model'
import * as Control from './control'

import OnlineMap from './OnlineMap'
import OfflineMap from './OfflineMap'
import MapCaptureDialog from './MapCaptureDialog'
import MapDeleteDialog from './MapDeleteDialog'
import MapListView from './MapListView'
import SettingsView from './SettingsView'
import MenuButtonView from './MenuButtonView'
import {
  IconOnlineMap,
  IconSavedMaps,
} from './MenuButtonView'
import splashBackgroundUrl from './media/splash.jpg'
import splashLogoUrl from './media/logo.png'



function OnlineMapButtonView(props) {
  return (
    <a
      className="btn btn-outline-primary"
      style={{border: 'none', fontSize: '0.8rem'}}
      href="#/onlinemap"
    >
      <IconOnlineMap size="3x" /><br/>Online Map
    </a>
  )
}


function HomeButtonsView(props) {
  return (
    <div className="row mt-4 mx-4">
      <div className="col-6">
        <OnlineMapButtonView />
      </div>
      <div className="col-6">
        <a
          className="btn btn-outline-primary"
          style={{border: 'none', fontSize: '0.8rem'}}
          href="#/maps"
        >
          <IconSavedMaps size="3x" /><br/>Saved Maps
        </a>
      </div>
    </div>
  )
}


function HomeView(props) {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundImage: `url("${splashBackgroundUrl}")`,
        backgroundSize: "1100px auto",
        backgroundPosition: "center",
      }}
    >
      <div className="row text-center ml-0" style={{maxWidth: "100%"}}>
        <div className="col-12 col-sm-6 my-4">
          <img
            src={splashLogoUrl}
            width="80%"
            style={{opacity: ".8"}}
            alt="go!"
          />
        </div>
        <div
          className="col-12 col-sm-6"
        >
          {props.loading ? (
            <FontAwesomeIcon
              icon={faSpinner}
              spin
              size="3x"
              className="mt-4 text-primary"
            />
          ) : (
            <HomeButtonsView />
          )}
        </div>
      </div>
    </div>
  )
}


function MapList(props) {
  const {state, dispatch} = useStore()

  const mapdb = M.maps.getAllMaps(state)
  const location = M.geolocation.get(state).location
  const maps = useMemo(() =>
    Object.entries(mapdb).map(([mapid, mapdef]) => ({
        mapid,
        name: mapdef.usage.name,
        openMapHref: `#/map/${mapid}`,
        incomplete: mapdef.usage.incomplete,
        progress: mapdef.runtime.progress,
        nearby: !!location &&
          Leaflet.L.latLng(mapdef.spec.center).distanceTo(location) < (100 * 1000),
        contains: !!location &&
          Leaflet.L.latLngBounds(mapdef.spec.bounds).contains(location),
        onDelete: () => dispatch(M.mapDelete.propose(mapid)),
      })), [mapdb, location, dispatch])

  return (
    <div>
      <nav className="navbar navbar-dark bg-dark">
        <MenuButtonView />
        <span className="navbar-text">
          <FontAwesomeIcon
            icon={faFolder}
            className="mr-2"
            transform="grow-8"
          />
          Saved Maps
        </span>
      </nav>

      {maps.length > 0 ? (

      <MapListView maps={maps} />

      ) : (

      <div className="container">
        <div className="row my-4 justify-content-center">
          <div className="col-6">
            <div className="text-center text-muted">
              <em>
                You don't have any saved maps.
                Use the online map to save a map.
              </em>
            </div>
          </div>
        </div>
        <div className="row my-4 justify-content-center">
          <div className="col-auto">
            <OnlineMapButtonView />
          </div>
        </div>
      </div>

      )}

    </div>
  )
}


function Settings(props) {
  const {state, dispatch} = useStore()

  const updateAvailable = M.browser.getUpdateAvailable(state)
  const totalTiles = Object.values(M.maps.getAllMaps(state))
    .reduce((a, m) => a + m.spec.nTiles, 0)

  const [S] = useState(() => ({
      dispatch,
      handleCheckForUpdate: () => S.dispatch(M.browser.checkForUpdate()),
      handleAcceptUpdate: () => S.dispatch(M.browser.acceptUpdate()),
    }))

  return (
    <div>
      <nav className="navbar navbar-dark bg-dark">
        <MenuButtonView />
        <span className="navbar-text">
          <FontAwesomeIcon
            icon={faCog}
            className="mr-2"
            transform="grow-8"
          />
          Settings
        </span>
      </nav>
      <SettingsView
        updateAvailable={updateAvailable}
        onCheckForUpdate={S.handleCheckForUpdate}
        onAcceptUpdate={S.handleAcceptUpdate}
        totalTiles={totalTiles}
      />
    </div>
  )
}


function BusyOverlayView(props) {
  return (
    <div
      style={{
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
    >
      <div
        style={{
            position: 'absolute',
            top: '40%',
            left: '50%',
          }}
      >
        <div style={{position: 'relative', left: '-50%'}}>
          <FontAwesomeIcon icon={faSpinner} spin size="3x" />
        </div>
      </div>
    </div>
  )
}


function AppView(props) {
  const {state} = useStore()

  const location = M.browser.getLocation(state)
  const path = location ? location.pathname : '/'
  const mapsLoading = M.maps.getLoading(state)
  const updateInProgress = M.browser.getUpdateInProgress(state)

  const [longDelay, setLongDelay] = useState(false)
  useEffect(() => {
      const timeout = setTimeout(() => setLongDelay(true), 300)
      return () => clearTimeout(timeout)
    }, [setLongDelay])

  var screen
  if (path === '/onlinemap')
    screen = <OnlineMap />
  else if (path === '/maps')
    screen = <MapList />
  else if (path === '/settings')
    screen = <Settings />
  else if (path.startsWith('/map/')) {
    if (mapsLoading && longDelay)
      screen = <HomeView loading/>
    else if (mapsLoading)
      screen = null
    else
      screen = <OfflineMap mapid={path.slice(5)} />
  }
  else
    screen = <HomeView />

  return (
    <React.Fragment>
      <MapCaptureDialog />
      <MapDeleteDialog />
      {screen}
      {updateInProgress ? <BusyOverlayView /> : null}
    </React.Fragment>
  )
}

function App() {
  return (
    <WithReducerMap map={M.reducerMap}>
      <Control.BrowserLocationController />
      <Control.ServiceWorkerController />
      <Control.MapTileDownloader />
      <Control.MapReaper />
      <Control.MapListLoader />
      <Control.MapCaptureController />
      <Control.GeolocationController />
      <AppView />
    </WithReducerMap>
  )
}

export default App;
