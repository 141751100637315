
import React from 'react'

import * as Leaflet from './Leaflet'


import locationIconUrl from './media/location-marker.min.svg'
import locationHeadingIconUrl from './media/location-heading-marker.min.svg'
import locationStaleIconUrl from './media/location-stale-marker.min.svg'

const locationIcon = Leaflet.L.icon({
    iconUrl: locationIconUrl,
    iconSize: [66, 66],
    iconAnchor: [33, 33],
  })
const locationHeadingIcon = Leaflet.L.icon({
    iconUrl: locationHeadingIconUrl,
    iconSize: [66, 66],
    iconAnchor: [33, 33],
  })
const locationStaleIcon = Leaflet.L.icon({
    iconUrl: locationStaleIconUrl,
    iconSize: [66, 66],
    iconAnchor: [33, 33],
  })


// props:
//   location: [lat, lng]
//   heading: degrees
//   stale: boolean
export default function GeoLocationMarker(props) {
  const {
    location,
    stale,
  } = props

  const heading = (typeof props.heading === 'number' &&
                  !isNaN(props.heading)) ? props.heading : undefined

  const icon =               !location ? undefined
               :                 stale ? locationStaleIcon
               : heading !== undefined ? locationHeadingIcon
               :                         locationIcon

  if (icon)
    return (
        <Leaflet.Marker
          icon={icon}
          latLng={location}
          rotationAngle={heading}
        />
      )
  else
    return null
}

