
import {
  useEffect,
  useRef,
  useState,
} from 'react'

import co from '../core/co'

import useStore from '../core/store'
import M from '../model'

import * as DB from '../db'



export function MapReaper(props) {
  const {takeEvery} = useStore()
  const {current: S} = useRef({})
  S.takeEvery = takeEvery

  const [queue, setQueue] = useState([])
  const mapid = queue[0]

  useEffect(() => co(function* () {
      const deletedMaps = yield DB.getDeletedMaps()
      deletedMaps.forEach(mapid => setQueue(q => [...q, mapid]))
    }).cancel, [])

  useEffect(() => co(function* () {
      yield* S.takeEvery(M.mapDelete.actions.COMMIT, function* (action) {
          const {mapid} = action
          // mark for deletion
          yield DB.deleteMap(mapid)
          setQueue(q => [...q, mapid])
        })
    }).cancel, [S])

  useEffect(() => co(function* () {
      if (!mapid) return;
      yield* DB.reapMap(mapid)
      setQueue(([top, ...q]) => q)
    }).cancel, [mapid])

  return null
}

