
import React from 'react'
import {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

// leaflet
import 'leaflet/dist/leaflet.css'
import L from 'leaflet/dist/leaflet.js'
import 'leaflet-rotatedmarker'


export { L }


const MapContext = React.createContext()
export const useMap = () => useContext(MapContext)


// props:
//   options: passed to L.map()
//   view: {center: [lat, lng], zoom}
//   onViewChange: (view) => {}
//   style: style for map div
//   children
export function Map(props) {
  const {
    options,
    view,
    onViewChange,
  } = props

  const {current: S} = useRef({})
  S.options = options
  S.view = view
  S.onViewChange = onViewChange
  if (!S.handleViewChange)
    S.handleViewChange = () => {
        const b = S.map.getBounds()
        const c = S.map.getCenter()
        const z = S.map.getZoom()
        if (S.lastViewChange &&
            S.lastViewChange.center[0] === c.lat &&
            S.lastViewChange.center[1] === c.lng &&
            S.lastViewChange.zoom === z)
          return;
        const view = {
            bounds: [[b.getNorth(), b.getWest()], [b.getSouth(), b.getEast()]],
            center: [c.lat, c.lng],
            zoom: z
          }
        if (S.onViewChange)
          S.onViewChange(view)
        S.lastViewChange = view
      }

  const [map, setMap] = useState()
  const mapDivRef = useRef()
  useEffect(() => {
      S.map = L.map(mapDivRef.current, {
          ...S.options,
          center: S.view.center,
          zoom: S.view.zoom,
        })
      S.map.on('moveend', S.handleViewChange)
      S.map.on('zoomend', S.handleViewChange)
      S.handleViewChange()
      setMap(S.map)
      return () => S.map.remove()
    }, [S])

  useEffect(() => {
      if (!S.map) return;
      const center = S.map.getCenter()
      const zoom = S.map.getZoom()
      if (view.bounds) {
          S.map.flyToBounds(view.bounds, {
              maxZoom: S.options.maxNativeZoom,
            })
      }
      else {
        if (view.center[0] !== center.lat ||
            view.center[1] !== center.lng ||
            view.zoom !== zoom)
          S.map.flyTo(view.center, view.zoom)
      }
    }, [view, S])

  return (
    <React.Fragment>
      <div
        ref={mapDivRef}
        style={props.style}
      >
      </div>
      {map ? (
        <MapContext.Provider value={map}>
          {props.children}
        </MapContext.Provider>
      ) : null}
    </React.Fragment>
  )
}


// props:
//   layer
export function LayerOnMap(props) {
  const {
    layer,
  } = props

  const map = useMap()

  useEffect(() => {
      if (layer && map) {
        layer.addTo(map)
        return () => layer.remove()
      }
    }, [layer, map])

  return null
}


// props:
//   latLng: [lat, lng]
//   icon: L.icon
//   rotationAngle: degrees
export function Marker(props) {
  const {
    latLng,
    icon,
    rotationAngle,
  } = props

  const {current: S} = useRef({})
  if (!S.marker) {
    S.marker = L.marker(latLng, {icon, rotationAngle})
    S.latLng = latLng
    S.icon = icon
    S.rotationAngle = rotationAngle
  }

  if (latLng !== S.latLng) {
    S.marker.setLatLng(latLng)
    S.latLng = latLng
  }

  if (icon !== S.icon) {
    S.marker.setIcon(icon)
    S.icon = icon
  }

  if (rotationAngle !== S.rotationAngle) {
    S.marker.setRotationAngle(rotationAngle)
    S.rotationAngle = rotationAngle
  }

  return <LayerOnMap layer={S.marker} />
}

