import React from 'react'

// fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBars,
  faCog,
  faFolder,
  faHome,
  faMap,
  faWifi,
} from '@fortawesome/free-solid-svg-icons'


export const IconOnlineMap = props => (
  <FontAwesomeIcon
    icon={faWifi}
    fixedWidth
    transform="shrink-5"
    mask={faMap}
    {...props}
  />)

export const IconSavedMaps = props => (
  <FontAwesomeIcon icon={faFolder} fixedWidth {...props} />)


export function MenuButtonDropdownView(props) {
  return (
    <div className="dropdown-menu" style={{minWidth: 0}}>
      <a className="dropdown-item px-3 py-2" href="#/">
        <FontAwesomeIcon icon={faHome} fixedWidth size="2x"/>
      </a>
      <a className="dropdown-item px-3 py-2" href="#/maps">
        <IconSavedMaps size="2x" />
      </a>
      <a className="dropdown-item px-3 py-2" href="#/onlinemap">
        <IconOnlineMap size="2x" />
      </a>
      <a className="dropdown-item px-3 py-2" href="#/settings">
        <FontAwesomeIcon icon={faCog} fixedWidth size="2x"/>
      </a>
    </div>
  )
}


export default function MenuButtonView(props) {
  return (
    <div className="dropdown">
      <button
        className="navbar-brand btn pl-0"
        data-toggle="dropdown"
        style={{fontSize: "1.3rem"}}
      >
        <FontAwesomeIcon icon={faBars} fixedWidth />
      </button>
      <MenuButtonDropdownView />
    </div>
  )
}

