import React from 'react';
import ReactDOM from 'react-dom';

import './_topogogo.scss';
import 'bootstrap'

import {WithStore} from './core/store'
import App from './App';

import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
    <WithStore>
      <App />
    </WithStore>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
