
import {
  useEffect,
  useState,
} from 'react'

import useStore from '../core/store'
import co from '../core/co'
import M from '../model'


// options:
//   onControllerChange: (serviceworker) => {}  // new controller
//   onUpdateAvailable: (serviceworker) => {}  // waiting worker
// returns:
//   function to cancel monitoring
function monitorServiceWorker(options={}) {
  var registration

  function handleControllerChange() {
    options.onControllerChange &&
      options.onControllerChange(navigator.serviceWorker.controller)
  }

  var cancelStateChangeWatch = () => undefined
  function handleUpdateFound() {
    if (registration.waiting) {
      cancelStateChangeWatch()
      options.onUpdateAvailable &&
        options.onUpdateAvailable(registration.waiting)
    }
    else if (registration.installing) {
      cancelStateChangeWatch()
      var installing = registration.installing
      installing.addEventListener('statechange', handleUpdateFound)
      cancelStateChangeWatch = () => {
          installing.removeEventListener('statechange', handleUpdateFound)
          cancelStateChangeWatch = () => undefined
        }
    }
  }

  function handleReady(r) {
      registration = r
      registration.addEventListener('updatefound', handleUpdateFound)
      if (registration.installing || registration.waiting)
        handleUpdateFound()
  }
  var onReady = handleReady

  navigator.serviceWorker.addEventListener(
    'controllerchange', handleControllerChange)

  navigator.serviceWorker.ready.then(r => onReady && onReady(r))

  // return a cancel method
  return () => {
    onReady = false
    registration && registration.removeEventListener(
      'updatefound', handleUpdateFound)
    cancelStateChangeWatch()
    navigator.serviceWorker.removeEventListener(
      'controllerchange', handleControllerChange)
  }
}


function dynamicPrecache(sw) {
  const urlsToCache = [
      window.location.href,
      ...window.performance.getEntriesByType('resource').map(r => r.name),
    ]

  sw.postMessage({
      type: 'CACHE_URLS',
      payload: {urlsToCache},
    })
}

export function ServiceWorkerController(props) {
  const {dispatch, take} = useStore()

  const [S] = useState(() => ({
      dispatch,
      take,
      handleControllerChange: (sw) => {
          if (S.reloadOnControllerChange) {
            window.location.reload()
          }
          else {
            if (S.waitingWorker === sw) S.waitingWorker = undefined;
            dynamicPrecache(sw)
          }
        },
      handleUpdateAvailable: (sw) => {
          S.waitingWorker = sw
          S.dispatch(M.browser.setUpdateAvailable(true))
        },
    }))

  useEffect(() => {
      return monitorServiceWorker({
          onControllerChange: S.handleControllerChange,
          onUpdateAvailable: S.handleUpdateAvailable,
        })
    }, [S])

  useEffect(() => co(function* () {
      while (true) {
        const a = yield S.take([
            M.browser.actions.CHECKFORUPDATE,
            M.browser.actions.UPDATEACCEPTED,
          ])
        if (a.type === M.browser.actions.CHECKFORUPDATE) {
          navigator.serviceWorker.ready.then(r => r.update())
        }
        else if (a.type === M.browser.actions.UPDATEACCEPTED) {
          if (S.waitingWorker) {
            S.dispatch(M.browser.setUpdateInProgress(true))
            S.reloadOnControllerChange = true
            S.waitingWorker.postMessage({type: 'SKIP_WAITING'})
          }
        }
      }
    }).cancel, [S])

  return null
}

