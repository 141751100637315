
import _co from 'co'


const CANCELLED = Symbol('co:cancelled')

function co(gen) {
  var ctx = this
  var args = Array.prototype.slice.call(arguments, 1)
  if (typeof gen === 'function')
    gen = gen.apply(ctx, args)
  var p = _co(gen)
  p.cancel = () => {
    try { gen.throw(CANCELLED) }
    catch (ignore) { }
  }
  return p
}

co.CANCELLED = CANCELLED
co.sleep = (duration) => new Promise(resolve => setTimeout(resolve, duration))

export default co

