
import * as idbkv from 'idb-keyval'


const prefixRange = prefix => IDBKeyRange.bound(
    prefix,
    prefix.slice(0, -1) +
      String.fromCodePoint(prefix.slice(-1).codePointAt(0)+1),
    false,
    true
  )


function getAllWithPrefix(store, prefix) {
  const all = []
  return store._withIDBStore('readonly', store => {
      const r = prefixRange(prefix);
      store.openCursor.call(store, r).onsuccess = function() {
          if (!this.result) return;
          all.push([this.result.key, this.result.value])
          this.result.continue()
        }
    }).then(() => all)
}


function getAllKeysWithPrefix(store, prefix) {
  const keys = []
  return store._withIDBStore('readonly', store => {
      const r = prefixRange(prefix);
      (store.openKeyCursor || store.openCursor).call(store, r).onsuccess = function() {
          if (!this.result) return;
          keys.push(this.result.key)
          this.result.continue()
        }
    }).then(() => keys)
}


function delAllWithPrefix(store, prefix) {
  const r = prefixRange(prefix);
  return idbkv.del(r, store)
}


const mapStore = new idbkv.Store('mapdb', 'mapstore')

const GENERATE_MAPID    = () => (Math.random()+1).toString(36).slice(2, 7)

const MAPLIST_KEYPREFIX = () => '/mapids/'
const MAPLIST_KEY       = mapid => MAPLIST_KEYPREFIX() + mapid
const MAPMETA_KEYPREFIX = mapid => (`/map/${mapid}/meta/`)
const MAPMETA_KEY       = (mapid, metaid) => MAPMETA_KEYPREFIX(mapid) + metaid
const MAPTILE_KEYPREFIX = mapid => (`/map/${mapid}/tiles/`)
const MAPTILE_KEY       = (mapid, tileid) => MAPTILE_KEYPREFIX(mapid) + tileid


function getAllMapids() {
  const prefix = MAPLIST_KEYPREFIX()
  return getAllWithPrefix(mapStore, prefix)
    .then(keys => keys.map(([k, v]) => [k.slice(prefix.length), v]))
}


export function getMapList() {
  return getAllMapids()
    .then(allids => allids
       .filter(([id, v]) => v)
       .map(([id]) => id))
}


export function getMapMeta(mapid, metaid) {
  return idbkv.get(MAPMETA_KEY(mapid, metaid), mapStore)
}


export function putMapMeta(mapid, metaid, data) {
  return idbkv.set(MAPMETA_KEY(mapid, metaid), data, mapStore)
}


export function getMapTileList(mapid) {
  const prefix = MAPTILE_KEYPREFIX(mapid)
  return getAllKeysWithPrefix(mapStore, prefix)
    .then(keys => keys.map(k => k.slice(prefix.length)))
}


/*
 map tile storage format...
 fetch returns?  can be arraybuffer or blob
 arraybuffer more portable (can't store blob in safari+ios)
 should we just assume caller will format?
 fetch -> yields response
 then store {type: response.headers.get('Content-Type'), buf: response.arrayBuffer()
 so:

   fetch().then(response => response.arrayBuffer().then(buf =>
       ({type: response.headers.get('Content-Type'), buf})))
     .then(imagedata => putMapTile(..., imagedata))

 create blob from imagedata:
   new Blob([imagedata.buf], {type: imagedata.type})
*/
export function getMapTile(mapid, tileid) {
  return idbkv.get(MAPTILE_KEY(mapid, tileid), mapStore)
}


export function putMapTile(mapid, tileid, imagedata) {
  return idbkv.set(MAPTILE_KEY(mapid, tileid), imagedata, mapStore)
}


function unusedMapid() {
  const mapid = GENERATE_MAPID()
  return idbkv.get(MAPLIST_KEY(mapid), mapStore)
    .then(exists => exists ? unusedMapid() : mapid)
}
export function createMap(mapmeta) {
  return unusedMapid()
    .then(mapid => idbkv.set(MAPLIST_KEY(mapid), true, mapStore)
        .then(() => {
            console.log(`mapid ${mapid}`)
            console.log(mapmeta)
            return Promise.all(
              Object.entries(mapmeta).map(([key, value]) =>
                putMapMeta(mapid, key, value))
            )
          })
        .then(() => mapid)
      )
}


/* mark map for deletion */
export function deleteMap(mapid) {
  return idbkv.set(MAPLIST_KEY(mapid), false, mapStore)
}


/* get a list of deleted mapids */
export function getDeletedMaps() {
  return getAllMapids()
    .then(allids => allids
       .filter(([id, v]) => !v)
       .map(([id]) => id))
}


/* reap map from the database */
export function* reapMap(mapid) {
  const tilePrefix = MAPTILE_KEYPREFIX(mapid)
  yield delAllWithPrefix(mapStore, tilePrefix)
  const metaPrefix = MAPMETA_KEYPREFIX(mapid)
  yield delAllWithPrefix(mapStore, metaPrefix)
  yield idbkv.del(MAPLIST_KEY(mapid), mapStore)
}

