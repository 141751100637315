
import React from 'react'
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState
} from 'react'

import Channel from 'async-csp'


function useReducerWithRenderNotice(reducer, initialState, init) {
  const {current: R} = useRef({
    lastId: 0,
    onNotify: {},
    notified: [],
  })
  const _init = useCallback(
    ([state, renderedIds]) => ([init ? init(state): state, renderedIds]),
    [init]
  )
  const _reducer = useCallback(
    ([state, renderedIds], [actionId, notified, action]) => {
      if (notified.length)
        renderedIds = renderedIds.filter(i => !notified.includes(i))
      if (actionId)
        renderedIds = [...renderedIds, actionId]
      return [reducer(state, action), renderedIds]
    },
    [reducer]
  )
  const [[state, renderedIds], dispatch, ...wrapped] = useReducer(
    _reducer, [initialState, []], _init)
  R.state = state
  const _dispatch = useCallback((action, onRender) => {
    if (onRender) {
      const actionId = ++R.lastId
      R.onNotify[actionId] = onRender
      return [
        dispatch([actionId, R.notified, action]),
        () => { delete R.onNotify[actionId] }
      ]
    }
    else {
      return dispatch([0, R.notified, action])
    }
  }, [dispatch, R])
  useEffect(() => {
    for (const actionId of renderedIds) {
      if (R.onNotify[actionId]) {
        R.onNotify[actionId](R.state)
        delete R.onNotify[actionId]
      }
    }
    R.notified = renderedIds
  }, [renderedIds, R])
  return useMemo(() => ([state, _dispatch]), [state, _dispatch])
}


function combinedReducerFromMap(map) {
  return (state, action) => {
    const nextState = Object.keys(map).reduce((nextState, r) => {
      nextState[r] = map[r](state[r], action)
      return nextState
    }, {})
    const noChanges = Object.keys(nextState).every(r => (
      state.hasOwnProperty(r) && nextState[r] === state[r]
    ))
    return noChanges ? state : nextState
  }
}


function chanWithPatternFilter(pattern) {
  const patterns = Array.isArray(pattern) ? pattern : [pattern]
  const filter = action => {
    const matches = patterns.find(p => {
      if (!p || p === '*')
        return true
      if (typeof p === 'function')
        return p(action)
      if (p === action.type)
        return true
      return false
    })
    return matches && action
  }
  return new Channel(filter)
}


const REDUCERMAP_ADD    = '__STORE/REDUCERMAP/ADD'
const REDUCERMAP_REMOVE = '__STORE/REDUCERMAP/REMOVE'

const StoreContext = React.createContext()

export const WithStore = React.memo(props => {

  const {current: S} = useRef({
    reducerMap: {},
    state: {},
  })
  if (!S.actionChan)
    S.actionChan = {
      channels: [],
      pipe: (c) => {
        S.actionChan.channels.push(c)
      },
      unpipe: (c) => {
        S.actionChan.channels = S.actionChan.channels.filter(i => i !== c)
      },
      put: (a) => {
        S.actionChan.channels.forEach(c => c.put(a))
      },
      close: () => {
        S.actionChan.channels.forEach(c => c.close())
        S.actionChan.channels = []
      },
    }
  useEffect(() => {
    return () => {
      S.actionChan.close()
    }
  }, [S])

  const [state, _dispatch] = useReducerWithRenderNotice(
    combinedReducerFromMap(S.reducerMap), {})
  S.state = state

  const dispatch = useCallback((action, onRender) => {
    var cancelled = false
    console.log('dispatch', action)
    const [_return, _cancel] = _dispatch(action, state => {
      S.actionChan.put(action)
      if (onRender && !cancelled)
        onRender(state)
    })
    return onRender ? [_return, () => {cancelled = true}] : _return
  }, [_dispatch, S])
  const dispatchResolved = useCallback(action => (
    (new Promise((resolve, reject) => {
      dispatch(action, resolve)
    })).then(state => action)),
    [dispatch]
  )
  dispatch.resolved = dispatchResolved

  const select = useCallback((selector, ...args) => {
    if (Array.isArray(selector))
      return selector.map(([s, ...args]) => s(S.state, ...args))
    else
      return selector(S.state, ...args)
  }, [S])

  const take = useCallback(pattern => {
    const c = chanWithPatternFilter(pattern)
    S.actionChan.pipe(c)
    return c.take().then(action => {
      S.actionChan.unpipe(c)
      c.close()
      return action === Channel.DONE ? undefined : action
    })
  }, [S])

  const takeEvery = useCallback((pattern, handler, onReady) => {
    function* g(pattern, handler) {
      const c = chanWithPatternFilter(pattern)
      S.actionChan.pipe(c)
      onReady && onReady()
      try {
        while (true) {
          const a = yield c.take()
          if (a === Channel.DONE) break
          yield* handler(a)
        }
      }
      finally {
        S.actionChan.unpipe(c)
        c.close()
      }
    }
    return g(pattern, handler)
  }, [S])

  const addReducerMap = useCallback(map => {
    for (const r of Object.keys(map))
      S.reducerMap[r] = map[r]
    const ready = dispatch.resolved({type: REDUCERMAP_ADD})
    const removeReducerMap = () => {
      for (const r of Object.keys(map))
        if (S.reducerMap[r] === map[r])
          delete S.reducerMap[r]
      dispatch({type: REDUCERMAP_REMOVE})
    }
    return [ready, removeReducerMap]
  }, [dispatch, S])

  const store = useMemo(
    () => ([
      {
        state,
        dispatch,
        select,
        take,
        takeEvery,
      },
      {
        addReducerMap,
      }
    ]),
    [
      state,
      dispatch,
      select,
      take,
      takeEvery,
      addReducerMap,
    ]
  )

  return (
    <StoreContext.Provider value={store}>
      {props.children}
    </StoreContext.Provider>
  )
})


export const WithReducerMap = React.memo((props) => {
  const [ready, setReady] = useState(false)
  const {current: S} = useRef({})
  S.internal = useContext(StoreContext)[1]  // internal portion

  useEffect(() => {
    const [ready, removeReducerMap] = S.internal.addReducerMap(props.map)
    ready.then(() => setReady(true))
    return () => {
      setReady(false)
      removeReducerMap()
    }
  }, [props.map, S])

  return (
    <React.Fragment>
      {ready ? props.children : null}
    </React.Fragment>
  )
})


function useStore() {
  const [store] = useContext(StoreContext)
  return store
}
export default useStore

