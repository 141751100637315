
import React from 'react'
import {
  useMemo,
  useRef,
} from 'react'

import * as Leaflet from './Leaflet'
import useStore from './core/store'
import M from './model'
import MapViewer from './MapViewer'


export function onlineTileLayer(source) {
  if (source.wms)
    return Leaflet.L.tileLayer.wms(source.url, source.options)
  else
    return Leaflet.L.tileLayer(source.url, source.options)
}


function OnlineMap(props) {
  const {state, dispatch} = useStore()

  const {current: S} = useRef({})
  S.dispatch = dispatch
  if (!S.handleViewChange)
    S.handleViewChange = view => S.dispatch(M.onlineMap.setView(view))

  const source = M.onlineMap.getSource(state)
  const view = M.onlineMap.getView(state)

  const tileLayer = useMemo(() => onlineTileLayer(source), [source])

  return (
    <MapViewer
      options={source.options}
      tileLayer={tileLayer}
      view={view}
      onViewChange={S.handleViewChange}
      buttons={[/*'search',*/ 'capture', 'flytolocation']}
    />
  )
}
export default OnlineMap

