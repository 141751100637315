import React from 'react'
import {
  useEffect,
  useRef,
  useState,
} from 'react'

import $ from 'jquery'

import useStore from './core/store'
import M from './model'


export function MapDeleteDialogView(props) {
  const modalRef = useRef()

  const isOpen = props.isOpen
  useEffect(() => {
      modalRef.current && $(modalRef.current).modal(isOpen ? 'show' : 'hide')
    }, [isOpen])

  return (
    <div
      ref={modalRef}
      className="modal fade"
      tabIndex="-1"
      role="dialog"
      data-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <h5>Delete Map?</h5>
            {props.name}<br/>({props.nTiles} tiles)
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={props.onCancel}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={props.onCommit}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}


export default function MapDeleteDialog(props) {
  const {state, dispatch} = useStore()

  const mapid = M.mapDelete.getProposed(state)
  const isOpen = !!mapid
  const mapdef = !!mapid ? M.maps.getMap(state, mapid)
                         : {spec: {}, usage: {}}

  const [S] = useState(() => ({
      handleCancel: () => S.dispatch(M.mapDelete.cancel()),
      handleCommit: () => S.dispatch(M.mapDelete.commit(S.mapid)),
    }))
  S.dispatch = dispatch
  S.mapid = mapid

  return (
    <MapDeleteDialogView
      isOpen={isOpen}
      nTiles={mapdef.spec.nTiles}
      name={mapdef.usage.name}
      onCancel={S.handleCancel}
      onCommit={S.handleCommit}
    />
  )
}

