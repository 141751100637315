
const M = {
  reducerMap: {}
}


const initialState = {

  browser: {
    location: undefined,
    updateAvailable: false,
    updateInProgress: false,
  },

  onlineMap: {
    source: {
      wms: true,
      url: "https://basemap.nationalmap.gov/arcgis/services/USGSTopo/MapServer/WMSServer",
      options: {
        attribution: 'Credit: U.S. Geological Survey',
        layers: 0,
        format: "image/jpeg",
        maxNativeZoom: 16,
      },
    },
    view: {
      center: [35.513151077520035, -96.416015625],
      zoom: 3,
      bounds: undefined,
    },
  },

  mapCapture: {
    canStart: false,
    canCommit: false,
    definition: undefined,
    dialogIsOpen: false,
    maxNTiles: 1500,
  },

  mapDelete: {
    proposed: undefined,
  },

  maps: {
    loading: true,
    maps: {},
  },

  geolocation: {
    location: undefined,         // [lat, lng]
    altitude: undefined,
    heading: undefined,          // degress true, 0 == N
    accuracy: undefined,         // radius
    altitudeAccuracy: undefined,
    timestamp: undefined,
    stale: true,
  },

}



const BROWSER_REFLECTLOCATION     = 'BROWSER/REFLECTLOCATION'
const BROWSER_CHECKFORUPDATE      = 'BROWSER/CHECKFORUPDATE'
const BROWSER_SETUPDATEAVAILABLE  = 'BROWSER/SETUPDATEAVAILABLE'
const BROWSER_SETUPDATEINPROGRESS = 'BROWSER/SETUPDATEINPROGRESS'
const BROWSER_UPDATEACCEPTED      = 'BROWSER/UPDATEACCEPTED'

const ONLINEMAP_SETSOURCE = 'ONLINEMAP/SETSOURCE'
const ONLINEMAP_SETVIEW   = 'ONLINEMAP/SETVIEW'

const MAPCAPTURE_SETCANSTART     = 'MAPCAPTURE/SETCANSTART'
const MAPCAPTURE_SETCANCOMMIT    = 'MAPCAPTURE/SETCANCOMMIT'
const MAPCAPTURE_SETDEFINITION   = 'MAPCAPTURE/SETDEFINITION'
const MAPCAPTURE_SETUSAGENAME    = 'MAPCAPTURE/SETUSAGENAME'
const MAPCAPTURE_SETDIALOGISOPEN = 'MAPCAPTURE/SETDIALOGISOPEN'
const MAPCAPTURE_STARTCAPTURE    = 'MAPCAPTURE/STARTCAPTURE'
const MAPCAPTURE_COMMITCAPTURE   = 'MAPCAPTURE/COMMITCAPTURE'
const MAPCAPTURE_CANCELCAPTURE   = 'MAPCAPTURE/CANCELCAPTURE'

const MAPDELETE_PROPOSE = 'MAPDELETE/PROPOSE'
const MAPDELETE_CANCEL  = 'MAPDELETE/CANCEL'
const MAPDELETE_COMMIT  = 'MAPDELETE/COMMIT'

const MAPS_MAPLOADED    = 'MAPS/MAPLOADED'
const MAPS_MARKCOMPLETE = 'MAPS/MARKCOMPLETE'
const MAPS_SETPROGRESS  = 'MAPS/SETPROGRESS'
const MAPS_SETLOADING   = 'MAPS/SETLOADING'

const GEOLOC_UPDATE      = 'GEOLOC/UPDATE'
const GEOLOC_UNAVAILABLE = 'GEOLOC/UNAVAILABLE'


function reducer(state = initialState, action) {
  switch(action.type) {

    case BROWSER_REFLECTLOCATION:
      return {
        ...state,
        browser: {
          ...state.browser,
          location: action.location,
        },
      }

    case BROWSER_SETUPDATEAVAILABLE:
      return {
        ...state,
        browser: {
          ...state.browser,
          updateAvailable: action.available,
        },
      }

    case BROWSER_SETUPDATEINPROGRESS:
      return {
        ...state,
        browser: {
          ...state.browser,
          updateInProgress: action.inProgress,
        },
      }

    case ONLINEMAP_SETSOURCE:
      return {
        ...state,
        onlineMap: {
          ...state.onlineMap,
          source: action.source,
        },
      }

    case ONLINEMAP_SETVIEW:
      return {
        ...state,
        onlineMap: {
          ...state.onlineMap,
          view: action.view,
        },
      }

    case MAPCAPTURE_SETCANSTART:
      return {
        ...state,
        mapCapture: {
          ...state.mapCapture,
          canStart: action.canStart,
        },
      }

    case MAPCAPTURE_SETCANCOMMIT:
      return {
        ...state,
        mapCapture: {
          ...state.mapCapture,
          canCommit: action.canCommit,
        },
      }

    case MAPCAPTURE_SETDEFINITION:
      return {
        ...state,
        mapCapture: {
          ...state.mapCapture,
          definition: action.definition,
        },
      }

    case MAPCAPTURE_SETUSAGENAME:
      return {
        ...state,
        mapCapture: {
          ...state.mapCapture,
          definition: {
            ...state.mapCapture.definition,
            usage: {
              ...state.mapCapture.definition.usage,
              name: action.name,
            },
          },
        },
      }

    case MAPCAPTURE_SETDIALOGISOPEN:
      return {
        ...state,
        mapCapture: {
          ...state.mapCapture,
          dialogIsOpen: action.isOpen,
        },
      }

    case MAPDELETE_PROPOSE:
      return {
        ...state,
        mapDelete: {
          ...state.mapDelete,
          proposed: action.mapid,
        },
      }

    case MAPDELETE_CANCEL:
      return {
        ...state,
        mapDelete: {
          ...state.mapDelete,
          proposed: undefined,
        },
      }

    case MAPDELETE_COMMIT: {
      const {[action.mapid]: deleted, ...otherMaps} = state.maps.maps
      return {
        ...state,
        mapDelete: {
          ...state.mapDelete,
          proposed: state.mapDelete.proposed === action.mapid
                      ? undefined
                      : state.mapDelete.proposed,
        },
        maps: {
          ...state.maps,
          maps: otherMaps,
        },
      }
    }

    case MAPS_MAPLOADED:
      return {
        ...state,
        maps: {
          ...state.maps,
          maps: {
            ...state.maps.maps,
            [action.mapid]: {
              ...action.mapdef,
              runtime: {},
            },
          },
        },
      }

    case MAPS_SETPROGRESS:
      return {
        ...state,
        maps: {
          ...state.maps,
          maps: {
            ...state.maps.maps,
            [action.mapid]: {
              ...state.maps.maps[action.mapid],
              runtime: {
                ...state.maps.maps[action.mapid].runtime,
                progress: action.progress,
              },
            },
          },
        },
      }

    case MAPS_MARKCOMPLETE: {
      const {incomplete, ...usage} = state.maps.maps[action.mapid].usage
      const {progress, ...runtime} = state.maps.maps[action.mapid].runtime
      return {
        ...state,
        maps: {
          ...state.maps,
          maps: {
            ...state.maps.maps,
            [action.mapid]: {
              ...state.maps.maps[action.mapid],
              runtime,
              usage,
            },
          },
        },
      }
    }

    case MAPS_SETLOADING:
      return {
        ...state,
        maps: {
          ...state.maps,
          loading: action.loading,
        },
      }

    case GEOLOC_UPDATE:
      return {
        ...state,
        geolocation: action.geolocation,
      }

    case GEOLOC_UNAVAILABLE:
      return {
        ...state,
        geolocation: initialState.geolocation,
      }

    default:
      return state

  }
}

M.reducerMap.root = reducer

M.browser = {

  actions: {
    CHECKFORUPDATE: BROWSER_CHECKFORUPDATE,
    UPDATEACCEPTED: BROWSER_UPDATEACCEPTED,
  },

  // selectors
  getLocation: ({root}) => root.browser.location,
  getUpdateAvailable: ({root}) => root.browser.updateAvailable,
  getUpdateInProgress: ({root}) => root.browser.updateInProgress,

  // actions
  reflectLocation: location => ({type: BROWSER_REFLECTLOCATION, location}),
  checkForUpdate: () => ({type: BROWSER_CHECKFORUPDATE}),
  setUpdateAvailable: available => ({
      type: BROWSER_SETUPDATEAVAILABLE,
      available
    }),
  setUpdateInProgress: inProgress => ({
      type: BROWSER_SETUPDATEINPROGRESS,
      inProgress
    }),
  acceptUpdate: () => ({type: BROWSER_UPDATEACCEPTED}),
}

M.onlineMap = {

  // selectors
  getSource: ({root}) => root.onlineMap.source,
  getView: ({root}) => root.onlineMap.view,

  // actions
  setSource: (source) => ({type: ONLINEMAP_SETSOURCE, source}),
  setView: (view) => ({type: ONLINEMAP_SETVIEW, view}),
}

M.mapCapture = {

  // actions
  actions: {
    START: MAPCAPTURE_STARTCAPTURE,
    COMMIT: MAPCAPTURE_COMMITCAPTURE,
    CANCEL: MAPCAPTURE_CANCELCAPTURE,
  },

  // selectors
  getCanStart: ({root}) => root.mapCapture.canStart,
  getCanCommit: ({root}) => root.mapCapture.canCommit,
  getDefinition: ({root}) => root.mapCapture.definition,
  getDialogIsOpen: ({root}) => root.mapCapture.dialogIsOpen,
  getMaxNTiles: ({root}) => root.mapCapture.maxNTiles,

  // actions
  setCanStart: (canStart) => ({type: MAPCAPTURE_SETCANSTART, canStart}),
  setCanCommit: (canCommit) => ({type: MAPCAPTURE_SETCANCOMMIT, canCommit}),
  setDefinition: (definition) => ({type: MAPCAPTURE_SETDEFINITION, definition}),
  setDialogIsOpen: (isOpen) => ({type: MAPCAPTURE_SETDIALOGISOPEN, isOpen}),
  setUsageName: (name) => ({type: MAPCAPTURE_SETUSAGENAME, name}),
  start: () => ({type: MAPCAPTURE_STARTCAPTURE}),
  commit: () => ({type: MAPCAPTURE_COMMITCAPTURE}),
  cancel: () => ({type: MAPCAPTURE_CANCELCAPTURE}),
}

M.mapDelete = {

  // actions
  actions: {
    COMMIT: MAPDELETE_COMMIT,
  },

  // selectors
  getProposed: ({root}) => root.mapDelete.proposed,

  // actions
  propose: (mapid) => ({type: MAPDELETE_PROPOSE, mapid}),
  cancel: () => ({type: MAPDELETE_CANCEL}),
  commit: (mapid) => ({type: MAPDELETE_COMMIT, mapid}),
}

M.maps = {

  // actions
  actions: {
    MAPLOADED: MAPS_MAPLOADED,
  },

  // selectors
  getAllMaps: ({root}) => root.maps.maps,
  getMap: ({root}, mapid) => root.maps.maps[mapid],
  getLoading: ({root}) => root.maps.loading,

  // actions
  mapLoaded: (mapid, mapdef) => ({type: MAPS_MAPLOADED, mapid, mapdef}),
  setProgress: (mapid, progress) => ({type: MAPS_SETPROGRESS, mapid, progress}),
  markComplete: (mapid) => ({type: MAPS_MARKCOMPLETE, mapid}),
  setLoading: (loading) => ({type: MAPS_SETLOADING, loading}),
}

M.geolocation = {

  // selectors
  get: ({root}) => root.geolocation,

  // actions
  update: (geolocation) => ({type: GEOLOC_UPDATE, geolocation}),
  unavailable: () => ({type: GEOLOC_UNAVAILABLE}),
}


export default M

