import React from 'react'
import {
  useEffect,
  useRef,
} from 'react'

import $ from 'jquery'

import useStore from './core/store'
import M from './model'


export function MapCaptureDialogView(props) {
  const modalRef = useRef()

  const isOpen = props.isOpen
  useEffect(() => {
      modalRef.current && $(modalRef.current).modal(isOpen ? 'show' : 'hide')
    }, [isOpen])

  const handleNameChange = (e) => {
      props.onNameChange && props.onNameChange(e.target.value)
    }

  return (
    <div
      ref={modalRef}
      className="modal fade"
      tabIndex="-1"
      role="dialog"
      data-backdrop="static"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <input
              className="form-control"
              type="text"
              placeholder="Map Name"
              onChange={handleNameChange}
              value={props.name}
              style={{fontSize: "16px"}}
            ></input>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={props.onCancel}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary"
              disabled={props.onSave ? false : true}
              onClick={props.onSave}
            >
              Save Map ({props.nTiles} tiles)
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}


export default function MapCaptureDialog(props) {
  const {state, dispatch} = useStore()

  const isOpen = M.mapCapture.getDialogIsOpen(state)
  const definition = M.mapCapture.getDefinition(state) ||
                     {spec: {}, usage: {name: ''}}
  const canCommit = M.mapCapture.getCanCommit(state)

  const {current: S} = useRef({})
  S.dispatch = dispatch
  if (!S.handleNameChange)
    S.handleNameChange = name => S.dispatch(M.mapCapture.setUsageName(name))
  if (!S.handleCancel)
    S.handleCancel = () => S.dispatch(M.mapCapture.cancel())
  if (!S.handleCommit)
    S.handleCommit = () => S.dispatch(M.mapCapture.commit())

  return (
    <MapCaptureDialogView
      isOpen={isOpen}
      nTiles={definition.spec.nTiles}
      name={definition.usage.name}
      onNameChange={S.handleNameChange}
      onCancel={S.handleCancel}
      onSave={canCommit ? S.handleCommit : undefined}
    />
  )
}

